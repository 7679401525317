import React, { useEffect, useState } from "react";
import LogoDark from "../../images/uploadImage.png";
import SimpleBar from "simplebar-react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { Icon, TooltipComponent } from "../../components/Component";
import { useTheme } from "../provider/Theme";
import { getCookie } from "../../utils/Utils";

const Appbar = () => {
  const theme = useTheme();
  const [data, setData] = useState(null);
  const [btnloader, setButtonloader] = useState(false);
  const [loading, setLoading] = useState(true);
  const [menu, setMenu] = useState([]);

  let currentUrl = window.location.pathname !== undefined ? window.location.pathname : null;

  const appSidebarClass = classNames({
    "nk-apps-sidebar": true,
    [`is-light`]: theme.appbar === "white",
    [`is-${theme.appbar}`]: theme.appbar !== "white" && theme.appbar !== "light",
  });

  const fetchData = async () => {
    try {
      const authToken = getCookie("authToken");
      const formData = new FormData();
      formData.append("field_id", "favicon");
      formData.append("field_id", "sidebar_logo");
      formData.append("field_id", "public_logo");
      formData.append("field_id", "logo");
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/public/get-site-setting`, {
        method: "POST",
        // body: formData,
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const responseData = await response.json();
      if (
        responseData &&
        responseData.data &&
        responseData.data.sidebar_logo &&
        responseData.data.sidebar_logo.image_data
      ) {
        setData(responseData.data.sidebar_logo.image_data);
      } else {
        console.warn("Sidebar logo image data not found in the response");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchMenuData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/sitesetting/get-sidebar-menu`, {
        method: "GET",
        headers: {
          authToken: getCookie("authToken", null),
          // Include any necessary headers like auth tokens if required by the API
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const responseData = await response.json();
      setMenu(responseData.data.menu);
    } catch (error) {
      console.error("Error fetching menu data:", error);
    }
  };

  useEffect(() => {
    fetchData();
    fetchMenuData();
  }, []);

  return (
    <div className={appSidebarClass}>
      <div className="nk-apps-brand">
        <div className="logo-link">
          <img className="logo-light logo-img" src={data || LogoDark} alt="logo" />
          <img className="logo-dark logo-img" src={data || LogoDark} alt="logo-dark" />
        </div>
      </div>
      <div className="nk-sidebar-element">
        <div className="nk-sidebar-body">
          <SimpleBar className="nk-sidebar-content">
            <div className="nk-sidebar-menu">
              <ul className="nk-menu apps-menu">
                {menu.map((item, index) => {
                  if (item.dashboard) {
                    return (
                      <React.Fragment key={index}>
                        <TooltipComponent id={"dashboard" + index} text={item.text} direction="right" />
                        <li
                          className={`nk-menu-item ${
                            currentUrl === process.env.PUBLIC_URL + item.link ? "active current-page" : ""
                          }`}
                          key={index}
                          id={"dashboard" + index}
                        >
                          <Link to={`${process.env.PUBLIC_URL + item.link}`} className="nk-menu-link">
                            <span className="nk-menu-icon">
                              <Icon name={item.icon}></Icon>
                            </span>
                          </Link>
                        </li>
                      </React.Fragment>
                    );
                  } else if (item.text === "Applications") {
                    return (
                      <React.Fragment key={index}>
                        <li className="nk-menu-hr"></li>
                        {item.subMenu.map((sub, idx) => {
                          return (
                            <React.Fragment key={idx}>
                              <TooltipComponent id={"app" + idx} text={sub.text} direction="right" />
                              <li
                                className={`nk-menu-item ${
                                  currentUrl === process.env.PUBLIC_URL + sub.link ? "active current-page" : ""
                                }`}
                                key={idx}
                                id={"app" + idx}
                              >
                                <Link to={`${process.env.PUBLIC_URL + sub.link}`} className="nk-menu-link">
                                  <span className="nk-menu-icon">
                                    <Icon name={sub.icon}></Icon>
                                  </span>
                                </Link>
                              </li>
                            </React.Fragment>
                          );
                        })}
                      </React.Fragment>
                    );
                  } else return <React.Fragment key={index}></React.Fragment>;
                })}
              </ul>
            </div>
          </SimpleBar>
        </div>
      </div>
    </div>
  );
};

export default Appbar;
